<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Experiment 3 - Report Sheet</h2>
    </v-row>

    <v-row class="mb-4">
      a) What was the temperature of the lab when you did the experiment?
    </v-row>

    <calculation-input
      v-model="inputs.roomTemp"
      class="mb-8 ml-n3"
      prepend-text="$\text{T}:$"
      append-text="$^\circ\text{C}$"
      :disabled="!allowEditing"
    />

    <v-row class="mb-3 mt-2">
      b) Fill in the 4 tables below using the data from each of the 12 trials (3 trials for 4
      different amounts of Mg - Lots 1 through 4) you performed in lab.
    </v-row>

    <v-row class="mb-7 ml-n7">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Lot 1}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 1}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 2}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 3}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Average}$" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass Mg (g)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL1T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL1T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL1T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL1Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles Mg (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL1T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL1T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL1T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL1Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Pressure (atm)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL1T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL1T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL1T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL1Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\ce{H2}\text{ (mL)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L1T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L1T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L1T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L1Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles }\ce{H2}\text{ (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L1T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L1T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L1T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L1Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <v-row class="mb-7 ml-n6">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Lot 2}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 1}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 2}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 3}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Average}$" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass Mg (g)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL2T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL2T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL2T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL2Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles Mg (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL2T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL2T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL2T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL2Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Pressure (atm)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL2T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL2T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL2T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL2Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\ce{H2}\text{ (mL)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L2T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L2T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L2T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L2Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles }\ce{H2}\text{ (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L2T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L2T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L2T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L2Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <v-row class="mb-7 ml-n6">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Lot 3}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 1}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 2}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 3}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Average}$" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass Mg (g)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL3T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL3T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL3T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL3Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles Mg (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL3T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL3T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL3T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL3Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Pressure (atm)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL3T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL3T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL3T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL3Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\ce{H2}\text{ (mL)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L3T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L3T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L3T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L3Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles }\ce{H2}\text{ (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L3T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L3T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L3T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L3Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <v-row class="mb-7 ml-n6">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Lot 4}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 1}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 2}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial 3}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Average}$" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass Mg (g)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL4T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL4T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL4T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.massMgL4Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles Mg (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL4T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL4T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL4T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molMgL4Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Pressure (atm)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL4T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL4T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL4T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.pressureL4Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\ce{H2}\text{ (mL)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L4T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L4T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L4T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.volH2L4Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Moles }\ce{H2}\text{ (mol)}$" />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L4T1"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L4T2"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L4T3"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input my-1 mx-0">
              <calculation-input
                v-model="inputs.molH2L4Avg"
                class="my-1 py-1 mx-0 px-0"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <v-row class="mb-n6">
      <p>c) Please upload a picture of your graph using the input field below.</p>
    </v-row>

    <v-row class="mb-n5">
      <v-col class="ml-n3" cols="4"> <b>Max file size</b>: 50 MB </v-col>
      <v-col cols="8"> <b>Accepted file types</b>: PDF, BMP, JPG, JPEG, PNG </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-file-input v-model="attachments" multiple />
    </v-row>

    <v-row class="mb-1">
      <v-col cols="12" class="ml-n2">
        c) What were the slope, y-intercept, and <stemble-latex content="$\text{R}^2" /> values from
        your graph of moles of <stemble-latex content="$\ce{H2}$" /> produced vs moles of Mg
        consumed?
      </v-col>
    </v-row>

    <calculation-input
      v-model="inputs.slope"
      class="mb-3 ml-n3"
      prepend-text="$\text{Slope:}$"
      append-text="$\ce{mol H2 / mol Mg}$"
      dense
      :disabled="!allowEditing"
    />

    <calculation-input
      v-model="inputs.yInt"
      class="ml-n3 mb-3"
      prepend-text="$\text{y-intercept:}$"
      append-text="$\ce{mol H2}$"
      dense
      :disabled="!allowEditing"
    />

    <calculation-input
      v-model="inputs.rSquared"
      class="ml-n3 mb-2"
      prepend-text="$\text{R}^2:$"
      dense
      :disabled="!allowEditing"
    />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'OleMissExperiment3ReportSheet',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        roomTemp: null,

        massMgL1T1: null,
        massMgL1T2: null,
        massMgL1T3: null,
        massMgL1Avg: null,

        molMgL1T1: null,
        molMgL1T2: null,
        molMgL1T3: null,
        molMgL1Avg: null,

        pressureL1T1: null,
        pressureL1T2: null,
        pressureL1T3: null,
        pressureL1Avg: null,

        volH2L1T1: null,
        volH2L1T2: null,
        volH2L1T3: null,
        volH2L1Avg: null,

        molH2L1T1: null,
        molH2L1T2: null,
        molH2L1T3: null,
        molH2L1Avg: null,

        massMgL2T1: null,
        massMgL2T2: null,
        massMgL2T3: null,
        massMgL2Avg: null,

        molMgL2T1: null,
        molMgL2T2: null,
        molMgL2T3: null,
        molMgL2Avg: null,

        pressureL2T1: null,
        pressureL2T2: null,
        pressureL2T3: null,
        pressureL2Avg: null,

        volH2L2T1: null,
        volH2L2T2: null,
        volH2L2T3: null,
        volH2L2Avg: null,

        molH2L2T1: null,
        molH2L2T2: null,
        molH2L2T3: null,
        molH2L2Avg: null,

        massMgL3T1: null,
        massMgL3T2: null,
        massMgL3T3: null,
        massMgL3Avg: null,

        molMgL3T1: null,
        molMgL3T2: null,
        molMgL3T3: null,
        molMgL3Avg: null,

        pressureL3T1: null,
        pressureL3T2: null,
        pressureL3T3: null,
        pressureL3Avg: null,

        volH2L3T1: null,
        volH2L3T2: null,
        volH2L3T3: null,
        volH2L3Avg: null,

        molH2L3T1: null,
        molH2L3T2: null,
        molH2L3T3: null,
        molH2L3Avg: null,

        massMgL4T1: null,
        massMgL4T2: null,
        massMgL4T3: null,
        massMgL4Avg: null,

        molMgL4T1: null,
        molMgL4T2: null,
        molMgL4T3: null,
        molMgL4Avg: null,

        pressureL4T1: null,
        pressureL4T2: null,
        pressureL4T3: null,
        pressureL4Avg: null,

        volH2L4T1: null,
        volH2L4T2: null,
        volH2L4T3: null,
        volH2L4Avg: null,

        molH2L4T1: null,
        molH2L4T2: null,
        molH2L4T3: null,
        molH2L4Avg: null,

        slope: null,
        yInt: null,
        rSquared: null,
      },
      attachments: [],
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
